<template>
  <div class="ticketPrice">
      <iframe scrolling: no
      :src="src"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import urls from "@/utils/baseUrl2";
export default {
  name: "ticketPrice",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      src: `${urls.baseUrl}tool/roomPriceIframe.html?sceneryId=${this.id}&isDevelopment=${urls.baseUrl}`,
      // src: `/static/ticketPrice/roomPriceIframe.html?sceneryId=${this.id}`,
    };
  },
  created() {},
};
</script>
<style lang="less" scoped>
.ticketPrice {
  height: 860px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>